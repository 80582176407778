
import { Vue } from "vue-class-component";
import { useStore } from "vuex";

export default class SideMenu extends Vue {
    private store = useStore();
    private sortParam = '1';
    private sidebarStatus = false;
    get earthquakes() {
        return this.store.getters.earthquakes.features;
    }
    get earthquakesFiltered() {
        if (!this.earthquakes) return this.earthquakes;
        if (this.sortParam === '1') {
            return this.earthquakes.sort((eq1: any, eq2: any) => eq2.properties.time - eq1.properties.time);
        } else if (this.sortParam === '2') {
            return this.earthquakes.sort((eq1: any, eq2: any) => eq1.properties.time - eq2.properties.time);
        } else if (this.sortParam === '3') {
            return this.earthquakes.sort((eq1: any, eq2: any) => eq2.properties.mag - eq1.properties.mag);
        } else {
            return this.earthquakes.sort((eq1: any, eq2: any) => eq1.properties.mag - eq2.properties.mag);
        }
    }

    private convertToTime(time: number) {
        let date: any = new Date(time);
        date =
            ("0" + date.getDate()).slice(-2) +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear() +
            " " +
            ("0" + date.getHours()).slice(-2) +
            ":" +
            ("0" + date.getMinutes()).slice(-2) +
            ":" +
            ("0" + date.getSeconds()).slice(-2);
        return date;
    }

    private toggleSidebar() {
        this.sidebarStatus = !this.sidebarStatus;
    }
}
