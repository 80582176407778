
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MapData from "@/components/MapData.vue"; // @ is an alias to /src
import SideMenu from "@/components/SideMenu.vue"; // @ is an alias to /src

@Options({
    components: {
        SideMenu,
        MapData,
    },
})
export default class Map extends Vue {
    @Prop({ default: "" })
    msg!: string;
}
