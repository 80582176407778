import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecfe9f62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mapDiv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_MapData = _resolveComponent("MapData")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideMenu),
    _createVNode(_component_MapData, { class: "map-data" })
  ]))
}